<template>
  <v-list-item
    class="notif-line"
    @click="navigateTo()"
    :class="{ 'secondary-10': item.unread }"
  >
    <v-list-item-avatar>
      <document-ref-icon
        visible
        :color="item.unread ? 'secondary' :'primary'"
        :icon-name="iconName"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex">
        <div
          class="notif-text-truncate"
          v-html="item.text"
        ></div>
        <br />
        <v-spacer />
        <calendar-date
          class="grey--text font-weight-regular notif-date"
          :date="item.date"
          timeline
        />
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        v-if="item.unread"
        :loading="markAsReadLoading"
        @click.stop="markAsRead"
      >
        <v-icon>{{$icon('i.Close')}}</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import navigation from '@/navigationController'
import { refreshCache } from '@/wasm/pkg'

export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    iconName () {
      if (!this.item?.docId.type) { return }
      return `i.${this.toPascalCase(this.item.docId.type)}`
    }
  },
  data () {
    return {
      markAsReadLoading: false
    }
  },
  methods: {
    async navigateTo () {
      navigation.navigateTo(this.item.docId.id, this.item.docId.type)
    },
    async markAsRead () {
      if (!this.markAsReadLoading) {
        this.markAsReadLoading = true
        try {
          await this.$http().post('/core/v6/notif/read-doc', { docId: this.item.docId })
          refreshCache()
          this.$emit('setAsRead', this.item)
        } finally {
          this.markAsReadLoading = false
        }
      }
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.secondary-10
  background-color var(--secondary-color-10)

.notif-line
  margin 2px 0

  .v-list-item__content
    max-width 30vw

    .notif-title, li
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

    ul
      padding-left 0

    .notif-text-truncate
      white-space pre-wrap
      overflow hidden
      text-overflow ellipsis

  .notif-date
    margin-left 1em
</style>
